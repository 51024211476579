import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, reactive, toRaw, computed, toRefs, onMounted, ref } from 'vue';
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import useTablePagination from '@/hooks/pagination';
import { selectBlacklistByPagination, getEmployee, insertHyBlacklist, deleteHyBlacklist, selectHyBlacklistPro } from '/src/api/personnel';
const columns = [{
  title: '姓名',
  dataIndex: 'name'
}, {
  title: '身份证号',
  dataIndex: 'IDnum'
}, {
  title: '所属项目',
  dataIndex: 'project'
}, {
  title: '加入黑名单时间',
  dataIndex: 'time'
}, {
  title: '加入黑名单原因',
  dataIndex: 'reason'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  setup() {
    onMounted(() => {
      selectBlacklistByPagination1();
      selectHyBlacklistPro1();
    }); ///////////////

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current; //判断当前是否为筛选查询

      if (isFilter) {
        onSubmit();
      } else {
        selectBlacklistByPagination1();
      }
    };

    const isFilter = ref(false);
    const loading = ref(false);
    const btnLoading = ref(false);
    let tableData = ref([]);
    let projectOptions = ref([]);
    const formRef = ref();
    const isInput = ref(false);
    const visible = ref(false);
    const visibleDetail = ref(false);

    const showModal = () => {
      visible.value = true;
    };

    const formState = reactive({
      name: '',
      IDNumber: '',
      belongProject: null
    }); //黑名单详情数据

    const detailState = reactive({
      name: '',
      IDNumber: '',
      project: '',
      reason: '',
      time: '',
      icon: ''
    });

    const onSubmit = () => {
      let submitData = {
        userName: formState.name,
        idCard: formState.IDNumber,
        belongProject: formState.belongProject,
        pageNumber: current.value == 0 ? '1' : current.value,
        limit: pageSize.value == 0 ? '10' : pageSize.value
      };
      filterBlacklistByPagination1(submitData);
    };

    const reset = () => {
      btnLoading.value = false;
      Object.assign(formState, {
        name: '',
        IDNumber: '',
        belongProject: null
      });
      current.value = '1';
      pageSize.value = '10';
      selectBlacklistByPagination1();
    };

    const deletePerson = e => {
      deleteHyBlacklist1({
        userNo: e.userNo,
        uid: e.uid
      });
    };

    const handleOk = () => {
      formRef.value.validate().then(() => {
        let updataState = {
          userName: newState.name,
          idCard: newState.IDNumber,
          belongProject: newState.belongProject,
          comment: newState.reason,
          icon: newState.icon,
          userNo: newState.userNo,
          uid: newState.uid
        };
        insertHyBlacklist1(updataState);
      }).catch(error => {
        console.log('error', error);
      });
    };

    const newState = reactive({
      name: '',
      IDNumber: '',
      project: '',
      reason: '',
      uid: ''
    });
    const rules = {
      name: [{
        required: true,
        message: '请输入姓名',
        trigger: 'blur'
      }],
      IDNumber: [{
        required: true,
        message: '请输入身份证号',
        trigger: 'blur'
      }],
      project: [{
        required: true,
        message: '请填写所属项目',
        trigger: 'blur'
      }],
      reason: [{
        required: true,
        message: '请填写加入黑名单原因',
        trigger: 'blur'
      }]
    };

    const onSearch = () => {
      if (newState.IDNumber == '' || newState.IDNumber == null) {
        _message.error('请输入身份证号');

        return;
      }

      getEmployee1({
        idCard: newState.IDNumber
      });
    };

    const handleCancel = () => {
      visible.value = false;
      formRef.value.resetFields();
      Object.assign(newState, {
        name: '',
        IDNumber: '',
        project: '',
        reason: ''
      });
      isInput.value = false;
    }; //获取黑名单列表


    const selectBlacklistByPagination1 = async () => {
      loading.value = true;
      let res = await selectBlacklistByPagination({
        pageNumber: current.value == 0 ? '1' : current.value,
        limit: pageSize.value == 0 ? '10' : pageSize.value
      });

      if (res.code === 200) {
        loading.value = false;
        console.log(res.data);
        let data = res.data;
        let list = res.data.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        isFilter.value = false;
        tableData.value = [];

        if (list != null) {
          list.filter((item, index) => {
            tableData.value.push({
              key: index + 1,
              name: item.userName,
              IDnum: item.idCard,
              project: item.projectName,
              time: item.createTime,
              reason: item.comment,
              icon: item.icon,
              userNo: item.userNo
            });
          });
        }
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    }; //获取黑名单人员项目集合


    const selectHyBlacklistPro1 = async () => {
      let res = await selectHyBlacklistPro();

      if (res.code === 200) {
        let list = res.data;
        projectOptions.value = [];
        list.filter((item, index) => {
          projectOptions.value.push({
            value: item.belongProject,
            label: item.projectName
          });
        });
      } else {
        _message.error(res.message);
      }
    }; //筛选黑名单列表


    const filterBlacklistByPagination1 = async val => {
      loading.value = true;
      let res = await selectBlacklistByPagination(val);

      if (res.code === 200) {
        loading.value = false;
        let data = res.data;
        let list = res.data.list;
        current.value = data.nowPage;
        pageSize.value = data.pageSize;
        total.value = data.countSize;
        isFilter.value = true;
        tableData.value = [];

        if (list != null) {
          list.filter((item, index) => {
            tableData.value.push({
              key: index + 1,
              name: item.userName,
              IDnum: item.idCard,
              project: item.projectName,
              time: item.createTime,
              reason: item.comment,
              icon: item.icon
            });
          });
        }
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    }; //通过身份证查询人员信息


    const getEmployee1 = async val => {
      let res = await getEmployee(val);
      console.log(res);

      if (res.code === 200) {
        if (res.data.length == 0) {
          _message.error('该人员不存在');
        } else if (res.data[0].isBlackList == 1) {
          _message.error('该人员已存在于黑名单');
        } else {
          let data = res.data[0];
          Object.assign(newState, {
            name: data.userName,
            IDNumber: data.idCard,
            belongProject: data.belongProject,
            project: data.projectName,
            icon: data.icon,
            userNo: data.userNo,
            uid: data.uid
          });
          isInput.value = true;
        }
      } else {
        _message.error(res.message);
      }
    }; //添加黑名单人员


    const insertHyBlacklist1 = async val => {
      btnLoading.value = true;
      let res = await insertHyBlacklist(val);

      if (res.code === 200) {
        _message.success(res.message);

        reset();
        visible.value = false;
        Object.assign(newState, {
          name: '',
          IDNumber: '',
          project: '',
          reason: ''
        });
        isInput.value = false;
      } else if (res.code === 10000) {
        _message.warning(res.message);

        reset();
        visible.value = false;
        Object.assign(newState, {
          name: '',
          IDNumber: '',
          project: '',
          reason: ''
        });
        isInput.value = false;
      } else {
        btnLoading.value = false;

        _message.error(res.message);
      }
    }; //移除黑名单


    const deleteHyBlacklist1 = async val => {
      let res = await deleteHyBlacklist(val);

      if (res.code === 200) {
        _message.success('移除成功');

        selectBlacklistByPagination1();
      } else if (res.code === 10000) {
        _message.warning(res.message);

        selectBlacklistByPagination1();
      } else {
        _message.error(res.message);
      }
    }; //详情


    const detailBlacklist = record => {
      visibleDetail.value = true;
      Object.assign(detailState, {
        name: record.name,
        IDNumber: record.IDnum,
        project: record.project,
        time: record.time,
        reason: record.reason,
        icon: record.icon
      });
    };

    return {
      tableData,
      columns,
      formState,
      onSubmit,
      projectOptions,
      deletePerson,
      showModal,
      visible,
      visibleDetail,
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      },
      handleOk,
      newState,
      rules,
      onSearch,
      isInput,
      formRef,
      handleCancel,
      reset,
      paginationConfig,
      loading,
      btnLoading,
      onPageChange,
      detailBlacklist,
      detailState
    };
  },

  components: {
    QuestionCircleOutlined
  }
});